importScripts(
  'https://storage.googleapis.com/workbox-cdn/releases/6.1.5/workbox-sw.js',
);

workbox.precaching.precacheAndRoute(self.__WB_MANIFEST);

// Cache all the js files to be network first strategy
workbox.routing.registerRoute(
  new RegExp('.*\.js\.gz'),
  new workbox.strategies.NetworkFirst(),
);

// Cache all the css files to be network first strategy
workbox.routing.registerRoute(
  new RegExp('.*\.css\.gz'),
  new workbox.strategies.NetworkFirst(),
);
